import React from "react"
import { Container, Col, Row, Card } from 'react-bootstrap'

import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const CustomCard = styled(Card.Body)`
  padding-top: 2rem;
  border-radius: 2rem;
  transition: transform 1s ease;
  &:hover {
    transform: scale(.9);
  }
`

const CardHeading = styled.h4`
  font-size: 1.15rem;
  font-weight: 900;
`


const ServiceCard = (props) => {
  return (
    <>
      <CustomCard className="p-5 my-4 mx-2 shadow-lg">
        {/*Took 1.44 hours to figure out icons. Awesome!*/}
        <span className="fa-stack fa-2x">
          <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" color={props.color} />
          <FontAwesomeIcon icon={props.icon} className="fa-stack-1x"inverse  />
        </span>
        <div className="px-2">
          <CardHeading className="py-4">{props.heading}</CardHeading>
          <p className="">{props.description}</p>
        </div>
      </CustomCard>
    </>
  )
}

export default ServiceCard
