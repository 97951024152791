import { Link } from "gatsby"
import React, { useState } from "react"
import { Container, Navbar, Nav, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CustomLink = styled(Link)`
  boxShadow: none;
  textDecoration: none;
  color: inherit;
`

// function getInitialState () {
//     return {
//       navExpanded: false
//     }
//   }
//
//   function setNavExpanded(expanded) {
//    this.setState({ navExpanded: expanded });
//  }
//
//  function closeNav() {
//    this.setState({ navExpanded: false });
//  }

const Header = () => {
  const [navbarState, setNavbar] = useState(false);
  // let windowsize = 10
  // if (typeof window !== 'undefined') {
  //  windowsize =  useWindowSize();
  // }
  // var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  //
  // const changeBackground = () => {
  //   // navbar transparent --> solid if scroll down X pizelx or if device width is < 768
  //   if(window.scrollY >= 100 || (window.scrollY >= 1 && width < 768)){
  //     setNavbar(true)
  //   }else {
  //     setNavbar(false)
  //   }
  // }
  // window.addEventListener('scroll', changeBackground)

  const [expanded, setExpanded] = useState(false);


  const navSections = ["Home", "About", "Services", "Contact", "Blog"]
  const numOfSections = navSections.length
  var navItemsHTML = []

  for (let navIndex = 0; navIndex < numOfSections; navIndex++){
    const section = navSections[navIndex].toLowerCase()
    const path = (navIndex == 4) ? "/" + section : "/#" + section

    navItemsHTML.push(
      <Nav.Item as="li" className = "py-2 pl-2 m-0">
      <Nav.Link>
          <AnchorLink
            to={path}
            title={section}
            className="navLink"
            activeClassName="active"
            >
           {section}
         </AnchorLink>
       </Nav.Link>
      </Nav.Item>
    )
  }


  return (
    <>

      <Navbar collapseOnSelect fixed="top" expand="lg" className="navbar solid"
       expanded={expanded}>
      {/*className= {navbarState ? 'navbar solid'  : 'navbar' }*/}
        <Container fluid>
          <Navbar.Brand className="navbar-brand"
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
          >
          <AnchorLink to="/#home">
            <img className="m-0" src={require('../assets/logo/long.png')} alt="Chrono Group"/>
          </AnchorLink>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="customTogglerIcon"
            onClick={() => setExpanded((prevExpanded) => (prevExpanded = !prevExpanded))}
          />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav as="ul" className="ml-auto navbarNav"
            onClick={() => setExpanded(false)}
            >
              {navItemsHTML}
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </>
  )
}

export default Header
