import React from "react"
import Header from "../components/header"
import LandingSection from "../components/landingSection"
import AboutSection from "../components/aboutSection"
import ServicesSection from "../components/servicesSection"
import BlogCard from "../components/blogcard"
import ContactForm from "../components/contactForm"
import scrollTo from 'gatsby-plugin-smoothscroll';
import 'bootstrap/dist/css/bootstrap.min.css'
import "../css/style.css"
import Messenger from "../components/messenger"
// import { Link } from "gatsby"
//
// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import Button from "../components/button"

const IndexPage = () => (

  <>
    <Header/>
    <LandingSection/>
    <AboutSection/>
    <ServicesSection/>
    <BlogCard />
    <ContactForm/>
    <Messenger/>
  </>

)

export default IndexPage
