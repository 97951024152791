import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import styled from "styled-components"

const BiggerH1 = styled.h1`
  font-size: 3rem;
  font-weight: 900;
`
const LandingSection = () => {
  return (
    <>
      {/*<section id="home" className="offset">
          <div className="container landing px-5">
            <div className="row landingouter">

              <div className="container-fluid">
                <div className="row landinginner">

                  <div className="col-lg-5 leftt pl-5">
                    <h1>Your brand reimagined</h1>
                    <p className="">
                    Combining industry expertise with innovative strategies, Chrono Group <em><strong>transforms</strong></em> small businesses and personal brands by leveraging analytics and targeted marketing.
                    </p>
                    <br/><br/>

                  </div>

                  <div className="col-lg-7 rightt">
                    <img src={require('../assets/illustrations/stand-out-blue.svg')} alt="Chrono Group"/>
                  </div>

                </div>
              </div>
            </div>

          </div>
      </section>*/}
      <Container id="home" className="landing offset mt-5">
        <Row className="justify-content-center">
          <Col lg={5} className="px-5 d-flex align-items-center" >
            <div>
              <h1 className="catchPhrase">Your brand reimagined</h1>
              <div className="pt-3">
              Combining industry expertise with innovative strategies, Chrono Group <em><strong>transforms</strong></em> small businesses and personal brands by leveraging analytics and targeted marketing.
              </div>
            </div>
          </Col>
          <Col lg={7} className="pt-5">
            <img src={require('../assets/illustrations/stand-out-blue.svg')} alt="Chrono Group"/>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default LandingSection
