import React from "react"
import { Container, Col, Row, Carousel, Card } from 'react-bootstrap'

import Slider from 'react-slick'
import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'

import styled from "styled-components"
import SectionTitle from "./sectionTitle"
import Slide from "./slide"


{/*import OwlCarousel from 'react-owl-carousel';*/}
{/*import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';*/}



export default function AboutSection(){
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000 // speed in mili-seconds
    // fade: true
  }
  const cardHeadings = [
    "Growth Marketing",
    "Websites and Analytics",
    "Social Media Handles"
  ]
  const cardDescriptions = [
    "Engage with customers through unique, well-orchestrated content campaigns on Instagram, Facebook and Google.",
    "Stand out with professionally built, visually appealing and customer-centric websites and applications.",
    "Build your brand and gain recognition by unlocking the full powers of social media to reach new audiences."
  ]
  const cardImages = [
    "target.svg",
    "data-trends-blue.svg",
    "social-dashboard-brown.svg"
  ]

  const slideHTML = []
  const numOfSlides = 3

  for (var slideIndex = 0; slideIndex < numOfSlides; slideIndex++){
    slideHTML.push(
      <Slide
        heading={cardHeadings[slideIndex]}
        description={cardDescriptions[slideIndex]}
        image={cardImages[slideIndex]}
      />
    )
  }
  return (
    <>
      <Container id="about" className="py-5 w-100 position-relative">
        <Row className="justify-content-center">
            <Col lg={12}>
              <SectionTitle title={"How Your Brand Works"}/>
            </Col>
            <Col lg={6}>
              <Slider {...settings}>
                {slideHTML}
              </Slider>
            </Col>
        </Row>
      </Container>

    </>
  )
}
