import React from "react"
import { Card, Button, Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import styled from "styled-components"
import SectionTitle from "./sectionTitle"

const CustomBlogCard = styled(Card.Body)`
padding-top: 2rem;
width: 100%;
border-radius: 2rem;
transition: transform 1s ease;
&:hover {
  transform: scale(.9);
`
const BlogCardBody = styled(Card.Body)``

const BlogCard = props => {
  return (
    <Container>
      <SectionTitle title={"Check out our Blog"} />
      <Row className="justify-content-center">
        <Col lg="6">
          <Link
            to="/blog/small-business-marketing-color-theory-part-1-introduction-to-colors"
            style={{ textDecoration: "none", color: "black" }}
          >
            <CustomBlogCard className="p-5 my-4 mx-2 shadow-lg">
              <Row>
                <Col lg="6">
                  <Card.Img
                    variant="top"
                    src={require(`../assets/illustrations/undraw_work_in_progress_uhmv.svg`)}
                    style={{ marginTop: "25%" }}
                  />
                </Col>

                <Col lg="6">
                  <Card.Body>
                    <Card.Title style={{ width: "100%" }}>
                      Embracing the Digital Transformation
                    </Card.Title>
                    <Card.Text>
                      Branding and marketing are often very difficult to tackle
                      and optimize. There are dozens of...
                    </Card.Text>
                    <Button
                      variant="primary"
                      href="/blog/small-business-marketing-color-theory-part-1-introduction-to-colors"
                    >
                      Go to article
                    </Button>
                  </Card.Body>
                </Col>
              </Row>
            </CustomBlogCard>
          </Link>
        </Col>

        <Col lg="6">
          <Link
            to="/blog/4-digital-marketing-strategies-for-restaurants-in-2021"
            style={{ textDecoration: "none", color: "black" }}
          >
            <CustomBlogCard className="p-5 my-4 mx-2 shadow-lg">
              <Row>
                <Col lg="6">
                  <Card.Img
                    variant="top"
                    src={require(`../assets/illustrations/undraw_all_the_data_h4ki.svg`)}
                    style={{ marginTop: "25%" }}
                  />
                </Col>

                <Col lg="6">
                  <Card.Body>
                    <Card.Title style={{ width: "100%" }}>
                      Embracing the Digital Transformation
                    </Card.Title>
                    <Card.Text>
                      In today’s business environment of fast moving
                      technological innovations...
                    </Card.Text>
                    <Button
                      variant="primary"
                      href="/blog/4-digital-marketing-strategies-for-restaurants-in-2021"
                    >
                      Go to article
                    </Button>
                  </Card.Body>
                </Col>
              </Row>
            </CustomBlogCard>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default BlogCard
