import React from "react"
import { Container, Col, Row, Card } from 'react-bootstrap'

import styled from "styled-components"
import SectionTitle from "./sectionTitle"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSearch,
  faLaptop,
  faTools,
  faCamera,
  faUsers,
  faCircle

} from "@fortawesome/free-solid-svg-icons";

import ServiceCard from "./serviceCard"



const ServicesSection = () => {
  const cardHeadings = [
    "Targeted Advertisement",
    "Search Engine Optimization",
    "Responsive Websites",
    "API Integration",
    "Photography & Graphic Design",
    "Social Media Following"
  ]

  const cardDescriptions = [
    "Achieve the highest ROI by reaching audiences most interested in your products and services. 90% of business owners reported that social media targeted ads generated great exposure.",

    "Become the first listing on Google search results with 27+ proven SEO strategies. 67k searches are performed on Google every second. 93% of all online experiences begin with a search engine.",

    "Redefine your brand with modern, responsive websites and mobile applications. 88% of online consumers are less likely to return to your site if they’ve had a bad experience.",

    "Get proactive with your brand reputation by highlighting real-time positive reviews. 75% of businesses say reviews are important to financial and reputational status of their business.",

    "Create your website and social media content with professional photoshoots and custom logos. 80% of the information that gets transmitted to the brain is visual.",

    "Build brand loyalty through a message that differentiates you from your competition. 52% of all businesses discovered happen on social media feeds."
  ]

  const cardIcons = [
    faFilter,
    faSearch,
    faLaptop,
    faTools,
    faCamera,
    faUsers
  ]

  const cardsHTML = []
  const numOfCards = 6

  for (var cardIndex = 0; cardIndex < numOfCards; cardIndex++){
    var iconColor = ""
    switch (cardIndex) {
      case 0: case 3: case 4:
        iconColor = "#BF6B1A"
        break;
      default:
        iconColor = "#1A6EB2"
    }
    cardsHTML.push(
      <Col sm={6} md={6}>
        <ServiceCard
          heading={cardHeadings[cardIndex]}
          description={cardDescriptions[cardIndex]}
          icon={cardIcons[cardIndex]}
          color={iconColor}
        />
      </Col>
    )
  }

  return (
    <>
      <Container id="services" className="py-5 w-100 position-relative">
        <Row className="justify-content-center">
            <Col lg={12}>
              <SectionTitle title={"Transform Your Brand"}/>
            </Col>
            {cardsHTML}
        </Row>
      </Container>
    </>
  )
}

export default ServicesSection
